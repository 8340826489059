@import "~@angular/material/prebuilt-themes/indigo-pink.css";
/* Global Styles */
* {
  font-family: Arial, Helvetica, sans-serif;
}
h1 {
  color: #264D73;
  font-size: 2.5rem;
}
h2, h3 {
  color: #444;
  font-weight: lighter;
}
h3 {
  font-size: 1.3rem;
}
body {
  padding: .5rem;
  max-width: 1000px;
  margin: auto;
}
@media (min-width: 600px) {
  body {
    padding: 2rem;
  }
}
body, input[text] {
  color: #333;
  font-family: Cambria, Georgia, serif;
}
a {
  cursor: pointer;
}
button.button{
  background-color: #eee;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: black;
  font-size: 1.2rem;
  padding: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
button:hover.button{
  background-color: black;
  color: white;
}
button:disabled.button {
  background-color: #eee;
  color: #aaa;
  cursor: auto;
}

/* Navigation link styles */
nav a {
  padding: 5px 10px;
  text-decoration: none;
  margin-right: 10px;
  margin-top: 10px;
  display: inline-block;
  background-color: #e8e8e8;
  color: #3d3d3d;
  border-radius: 4px;
}

nav a:hover {
  color: white;
  background-color:  #42545C;
}
nav a.active {
  background-color: black;
  color: white;
}
hr {
  margin: 1.5rem 0;
}
input[type="text"] {
  box-sizing: border-box;
  width: 100%;
  padding: .5rem;
}
